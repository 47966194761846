<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const props = defineProps({
  rotate: { 
    type: Boolean,
    default: false
  },
  sunClass: {
    type: String,
    default: '',
  }
})

const arcRadius = ref(1);
const strokeWidth = ref(2);
const delta = ref(0.8);
const arcs = ref([
  { x1: 250, x2: 350, o: 0.8 },
  { x1: 200, x2: 400, o: 0.6 },
  { x1: 150, x2: 450, o: 0.4 },
  { x1: 100, x2: 500, o: 0.2 },
]);

const calcArcPath = () => {
  for (let i = 0; i < arcs.value.length; i++) {
    arcs.value[i].x1 = arcs.value[i].x1 - delta.value;
    arcs.value[i].x2 = arcs.value[i].x2 + delta.value;
    arcs.value[i].o = arcs.value[i].o - delta.value / 180;

    if (arcs.value[i].x1 <= 50) {
      arcs.value.splice(i, 1);
      arcs.value.push({ x1: 250, x2: 350, o: 0.8 });
    }
  }
};

const printArcPath = (x1, x2) => {
  const startX = x1;
  const startY = 200;
  const endX = x2;
  const endY = 200;
  return `M${startX},${startY} A${arcRadius.value},${arcRadius.value} 0 0,1 ${endX},${endY}`;
};

const handleScroll = () => {
  calcArcPath();
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div class="relative flex justify-center items-center common" :class="sunClass">
    <svg class="arc" :class="{ 'rotate-180': rotate }" width="600" height="200" viewBox="0 0 600 200" :style="rotate ? 'bottom: -200px' : ''">
      <path d="M250,200 A1,1 0 0,1 350,200" fill="#F5F5F5" />
      <g>
        <path
          v-for="(arc, index) in arcs"
          stroke="#F5F5F5"
          fill="none"
          :stroke-width="strokeWidth"
          :stroke-opacity="arc.o"
          :d="printArcPath(arc.x1, arc.x2)"
        />
      </g>
    </svg>
  </div>
</template>
